import { CSSProperties } from "react";
import { logError } from "./error-utils";
import { cn } from "./shorthands";

export function arrowDirectionStyling(placement: string): CSSProperties {
	switch (placement) {
		case "top":
		case "top-start":
		case "top-end":
			return {
				bottom: "-0.25rem",
				transform: "rotate(45deg)",
			};
		case "bottom":
		case "bottom-start":
		case "bottom-end":
			return {
				top: "-0.25rem",
				transform: "rotate(225deg)",
			};
		case "left":
		case "left-start":
		case "left-end":
			return {
				right: "-0.25rem",
				transform: "rotate(315deg)",
			};
		case "right":
		case "right-start":
		case "right-end":
			return {
				left: "-0.25rem",
				transform: "rotate(135deg)",
			};
		default:
			logError(`Unexpected input <${placement}> was supplied to arrowDirectionStyling`);
			return {};
	}
}

export function mergeTransformStyles(a?: CSSProperties, b?: CSSProperties): CSSProperties {
	return {
		...a,
		...b,
		transform: cn(a?.transform, b?.transform),
	};
}
