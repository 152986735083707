import React, { useState, useEffect } from "react";
import { cn, stopEvent, useUniqueId } from "../../../utils/shorthands";
import { logError } from "../../../utils/error-utils";
import { KeyCode } from "../../../utils/keyboard-nav-utils";
import {
	DataComponent,
	DataObserveKey,
	VisualComponent,
} from "../../../types/fancy-base-attributes";
import { useTranslations } from "../../../context/fancy-context";
import { Icon } from "../../visuals/icons/icons";
import { BasePopover } from "../base-popover/base-popover";
import { InlineText } from "../../text/inline-text/inline-text";
import { SrOnly } from "../../text/sr-only/sr-only";
import { IconHelpOutline } from "../../visuals/icons/icons-list";
import { arrowDirectionStyling, mergeTransformStyles } from "../../../utils/styling-utils";
import * as scss from "./tooltip.scss";

export type TooltipProps = {
	/** The element that the tooltip clarifies the function or meaning of */
	children?: React.ReactChild | React.ReactFragment;
	/** text, icon-only, or interactive. Please read the styleguide documentation for guidance. */
	variant:
		| {
				type: "text";
				learnMoreAbout?: string;
				/** Set a different icon. The default icon and size are `Tooltip.icon` and `Tooltip.iconSize` */
				icon?: React.ReactNode;
		  }
		| { type: "icon-only" }
		| { type: "interactive" };

	/** Content to display when the tooltip is active */
	content: React.ReactChild;
	/** Preferred placement for the tooltip when active */
	placement?:
		| "top"
		| "bottom"
		| "right"
		| "left"
		| "top-start"
		| "top-end"
		| "bottom-start"
		| "bottom-end"
		| "right-start"
		| "right-end"
		| "left-start"
		| "left-end";
	/** Override the aria-label for the button wrapping the anchor element */
	"aria-label"?: string;
	/** Override the aria-describedby for the button wrapping the anchor element */
	"aria-describedby"?: string | null;
	/** Callback that's executed after the tooltip content gets hidden */
	onHide?: () => void;
	/** Force the tooltip to be in its visible state */
	forceOpen?: boolean;
	/** Remove the delay when showing and hiding the tooltip */
	noDelay?: boolean;
	/** Hide the content visually and for assistive technology */
	hideContent?: boolean;
	/** Key to control updates to the placement of the popover. Provide a different value when an update is desired */
	updateKeys?: React.DependencyList;
} & DataObserveKey &
	VisualComponent;

export function Tooltip(props: TooltipProps): JSX.Element {
	const {
		children,
		variant,
		content,
		placement = "top",
		onHide,
		forceOpen,
		noDelay,
		hideContent,
		updateKeys = [],
		style,
		className,
	} = props;

	const tooltipDelay = noDelay ? 0 : 100;
	const i18n = useTranslations();
	const [visible, setVisible] = useState(false);
	const [show, setShow] = useState(false);

	const showTooltip = () => {
		setShow(true);
	};
	const hideTooltip = () => {
		setShow(false);
	};

	useEffect(() => {
		const timeout = window.setTimeout(() => {
			setVisible(show);
			!show && onHide && onHide();
		}, tooltipDelay);

		return () => {
			if (timeout) window.clearTimeout(timeout);
		};
	}, [show]);

	const id = useUniqueId("tooltip");
	const contentId = useUniqueId("tooltip-content");
	const buttonId = useUniqueId("tooltip-button");
	const srOnlyId = useUniqueId("tooltip-sr-only");

	const nonInteractiveProps: React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> &
		DataComponent &
		DataObserveKey = {
		"data-component": "tooltip",
		"data-observe-key": props["data-observe-key"],
		type: "button",
		className: cn(scss.plainButton, className),
		style,
		onMouseEnter: showTooltip,
		onMouseLeave: hideTooltip,
		onKeyDown: (e) => {
			switch (e.keyCode) {
				case KeyCode.Escape:
					stopEvent(e);
					hideTooltip();
					return;
				case KeyCode.Enter:
				case KeyCode.Space:
					stopEvent(e);
					if (show) {
						hideTooltip();
					} else {
						showTooltip();
					}
					return;
			}
		},
		onClick: (e) => {
			stopEvent(e);
			if (show) {
				hideTooltip();
			} else {
				showTooltip();
			}
		},
	};

	let anchor = null;
	let wrapper = (x: JSX.Element) => <span>{x}</span>;

	switch (variant.type) {
		case "interactive":
			if (!React.isValidElement(children)) {
				logError("<Tooltip>: interactive variant must have exactly one child");
				return <></>;
			}
			anchor = (ref: React.Ref<HTMLDivElement>) => (
				<span id={contentId} ref={ref} className={cn(scss.referenceAnchor)}>
					{React.cloneElement(children, {
						"aria-describedby":
							props["aria-describedby"] === undefined ? srOnlyId : props["aria-describedby"],
					} as Partial<unknown>)}
				</span>
			);
			wrapper = (x) => (
				<span
					data-component="tooltip"
					data-observe-key={props["data-observe-key"]}
					className={cn(scss.referenceWrapper, className)}
					style={style}
					onFocus={showTooltip}
					onBlur={hideTooltip}
					onMouseOver={showTooltip}
					onMouseLeave={hideTooltip}
					onPointerLeave={hideTooltip}
					onKeyDown={(e) => {
						if (e.key === "Escape") {
							stopEvent(e);
							hideTooltip();
						}
					}}
				>
					{x}
				</span>
			);
			break;
		case "text":
			if (typeof children !== "string" && typeof children !== "number" && !variant.learnMoreAbout) {
				logError(
					"<Tooltip>: `text` variant children must be `string` or `number`, otherwise `variant.learnMoreAbout` must be provided."
				);
			}
			anchor = (ref: React.Ref<HTMLButtonElement>) => (
				<button
					id={buttonId}
					ref={ref}
					{...nonInteractiveProps}
					aria-expanded={visible}
					aria-controls={id}
					aria-labelledby={
						props["aria-label"] || variant.learnMoreAbout ? undefined : `${buttonId} ${contentId}`
					}
					aria-label={
						props["aria-label"] ?? `${i18n.learnMoreAbout}: ${variant.learnMoreAbout || ""}`
					}
					aria-roledescription={i18n.tooltip}
				>
					{variant.icon || <Icon size={Tooltip.iconSize}>{Tooltip.icon}</Icon>}
				</button>
			);
			wrapper = (x) => (
				<span className={cn(scss.textAndIconWrapper)}>
					{hideContent ? (
						<span id={contentId} aria-hidden={true}>
							<SrOnly>{children}</SrOnly>
						</span>
					) : (
						<span id={contentId}>{children}</span>
					)}
					{x}
				</span>
			);
			break;
		case "icon-only":
			anchor = (ref: React.Ref<HTMLButtonElement>) => (
				<button
					id={buttonId}
					ref={ref}
					{...nonInteractiveProps}
					aria-roledescription={i18n.tooltip}
					aria-labelledby={srOnlyId}
				>
					<span id={contentId}>{children}</span>
				</button>
			);
			break;
	}

	return wrapper(
		<>
			<BasePopover
				anchor={anchor}
				popover={({ arrowProps, placement }) => (
					<span
						className={scss.tooltip}
						onMouseEnter={showTooltip}
						onMouseLeave={hideTooltip}
						onPointerLeave={hideTooltip}
						role="tooltip"
					>
						<InlineText className={scss.tooltipText} tone="neutralLight" lineHeight="multi-line">
							{content}
						</InlineText>
						<div
							ref={arrowProps.ref}
							className={scss.tooltipArrow}
							style={mergeTransformStyles(arrowProps.style, arrowDirectionStyling(placement))}
						/>
					</span>
				)}
				placement={placement}
				showPopover={forceOpen || visible}
				offset={8}
				id={id}
				updateKeys={[content, ...updateKeys]}
				aria-hidden={
					variant.type === "interactive" || variant.type === "icon-only" ? true : undefined
				}
				zIndex={1600}
			/>
			{(variant.type === "interactive" || variant.type === "icon-only") && (
				<SrOnly id={srOnlyId} aria-hidden={true}>
					{content}
				</SrOnly>
			)}
		</>
	);
}

Tooltip.icon = <IconHelpOutline />;
Tooltip.iconSize = "medium" as const;
