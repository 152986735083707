import React, { ReactNode, useLayoutEffect } from "react";
import { cn, H1, Paragraph, TextContainer, Link } from "../../lib/src";
import { useStyeguideCtx } from "./docs-layout";
import * as scss from "./sections.scss";

type HeaderSectionProps = {
	title: ReactNode;
	subTitle: ReactNode;
	hideNav?: boolean;
	/** Some headings will automaticly be picked up by the build, otherwise can be manually added here */
	headings?: Heading[];
};
type Heading = { text: string; id: string };

export function HeaderSection(props: HeaderSectionProps): JSX.Element {
	let links: Heading[] = useStyeguideCtx().headings ?? [];
	if (props.headings) {
		links = links.concat(props.headings);
	}
	const anyLinks = !!links.length;

	return (
		<>
			<div className={cn(scss.headerOuter, anyLinks && scss.headerOuterWithStickyNav)}>
				<div className={cn(scss.headerInner, anyLinks && scss.headerInnerWithStickyNav)}>
					<BreadCrumbs />
					<TextContainer article>
						<H1>{props.title}</H1>
						<Paragraph size="xLarge" className={cn(scss.subtitle)}>
							{props.subTitle}
						</Paragraph>
					</TextContainer>
				</div>
			</div>
			{!props.hideNav && anyLinks && <StickyNavigation links={links} />}
		</>
	);
}

export function ContentSection(props: { children: ReactNode }): JSX.Element {
	const ref = React.useRef<HTMLDivElement>(null);
	useLayoutEffect(() => {
		const links = Array.from(ref.current!.querySelectorAll("a"));
		const ids = Array.from(ref.current!.querySelectorAll("[id]")).flatMap((e) =>
			e.id ? [e.id] : []
		);
		for (const link of links) {
			const href = link.getAttribute("href");
			if (href && href.startsWith("#")) {
				const linkId = href.substring(1);
				if (!ids.includes(linkId)) {
					console.error(`${href} is a broken link!`, link);
				}
			}
		}
	}, []);
	return (
		<div ref={ref} className={scss.content}>
			{props.children}
		</div>
	);
}

function BreadCrumbs(): JSX.Element | null {
	const path = useStyeguideCtx().pageUrl;

	if (!path || !path.includes("/components/")) {
		return null;
	}

	const [, env, components, category] = path.split("/");
	return <div className={scss.breadcrumbs}>{`${env} ${components} / ${category}`}</div>;
}

function StickyNavigation(props: { links: Heading[] }): JSX.Element {
	return (
		<div className={scss.stickyNav}>
			<div className={scss.stickyNavContent}>
				{props.links.map((l) => {
					return (
						<Link href={`#${l.id}`} size="large" key={l.id}>
							{l.text}
						</Link>
					);
				})}
			</div>
		</div>
	);
}
