import * as React from "react";
import { DataObserveKey, VisualComponent } from "../../../types/fancy-base-attributes";
import { cn } from "../../../utils/shorthands";

type TextContainerProps = {
	/** Content to be displayed inside the text container */
	children: React.ReactNode | React.ReactNode[];
	/** Style the text-container as article if set to true */
	article?: boolean;
} & DataObserveKey &
	VisualComponent;

export function TextContainer(props: TextContainerProps): JSX.Element {
	const { children, article, className, style } = props;
	return (
		<div
			data-observe-key={props["data-observe-key"]}
			data-component="text-container"
			className={cn(
				"typography-text-container",
				article && "typography-article-container",
				className
			)}
			style={style}
		>
			{children}
		</div>
	);
}
