import React from "react";
import { cn, f2u } from "../../../utils/shorthands";
import * as scss from "./sr-only.scss";

interface SrOnlyProps {
	/** Content of the element */
	children: React.ReactNode;
	/** Notifies assistive technologies of changes to the content. WARNING: SrOnly component must be present before content change for this to work. */
	status?: boolean;
	/** Style object to apply custom inline styles (only intended for special cases) */
	style?: React.CSSProperties;
	/** Will replace the SrOnly div with a span */
	isInline?: boolean;
	/** Optional ID */
	id?: string;
	/** Indicates whether the element is exposed to an accessibility API. */
	"aria-hidden"?: boolean;
}

export function SrOnly(props: SrOnlyProps): JSX.Element {
	return React.createElement(
		props.isInline ? "span" : "div",
		{
			"data-component": "sr-only",
			role: f2u(props.status && "status"),
			"aria-atomic": f2u(props.status),
			"aria-hidden": props["aria-hidden"],
			className: cn(scss.srOnly, "fancy-sr-only"),
			style: props.style,
			id: props.id,
		},
		props.children
	);
}
