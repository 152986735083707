import * as React from "react";
import { DataObserveKey, VisualComponent } from "../../../types/fancy-base-attributes";
import {
	alignmentClass,
	emphasisClass,
	sizeClass,
	toneClass,
	TypographyProps,
	lineHeightSingleLineClass,
} from "../typography";
import { cn } from "../../../utils/shorthands";
import * as scss from "./paragraph.scss";

type ParagraphProps = Partial<
	TypographyProps<"alignment" | "size" | "tone" | "emphasis" | "lineHeight">
> &
	DataObserveKey &
	VisualComponent;

export function Paragraph(props: ParagraphProps): JSX.Element {
	const { id, alignment, size, emphasis, tone, lineHeight, children, className, style } = props;
	return (
		<p
			data-observe-key={props["data-observe-key"]}
			data-component="paragraph"
			id={id}
			className={cn(
				scss.paragraph,
				alignment && alignmentClass(alignment),
				size && sizeClass(size),
				emphasis && emphasisClass(emphasis),
				tone && toneClass(tone),
				lineHeight === "single-line" && lineHeightSingleLineClass,
				className
			)}
			style={style}
		>
			{children}
		</p>
	);
}
